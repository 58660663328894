/** @jsx jsx */
import { jsx, Box, Text, Grid } from 'theme-ui';
import { useState, useEffect, useRef } from 'react'
import Layout from '../components/layout';
import Hero from '../components/hero';
import Article, { Byline } from '../components/article'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import { graphql } from 'gatsby'
import { Stack } from 'raam'
import { All, News } from '../components/feed'
import { Peek } from '../components/carousel'
import { PartnerCard } from '../components/card'
import { responsive } from '../utils/helpers'
import SectionHeader from '../components/sectionHeader'
import Promo from '../components/promo'
import MetaData from '../components/metadata'
import { Archive } from '../components/tickets'

const Tickets = (props) => {

  const page = props.data?.contentfulPage
  const hasByline = page.bylineMain && page.bylineAside
  const wrapperRef = useRef(false)
  const contentRef = useRef(false)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)
  const [peekLeft, setPeekLeft] = useState(0)
  const [peekRight, setPeekRight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  const carouselSlideSX = {
    'img': {
      width: '100%'
    }
  }

  const captionSX = {
    fontSize: 0,
    mt: 2,
  }

  const handleWindowWidthChange = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    if (!wrapperRef || !contentRef)
      return
    handleWindowWidthChange()
    window.addEventListener('resize', handleWindowWidthChange)
    setWrapperWidth(wrapperRef.current.offsetWidth)
    setContentWidth(contentRef.current.offsetWidth)
    return () => {
      window.removeEventListener('resize', handleWindowWidthChange);
    }
  }, [])

  useEffect(() => {
    const newPeekLeft = (windowWidth - wrapperWidth) / 2;
    const newPeekRight = windowWidth - (newPeekLeft + contentWidth)
    setPeekLeft(newPeekLeft)
    setPeekRight(newPeekRight)
  }, [wrapperWidth, contentWidth, windowWidth])

  return (
    <Layout {...props} light={page?.theme === 'light'}>
      <MetaData title="Tickets" />
      <Archive />

      {/* {page.hero &&
        <Hero 
          data={page.hero} 
          strap={page.strap}
          sx={{marginBottom: hasByline ? '0px' : 6}} 
          variant={page?.theme} 
          centeredTitle={props.pageContext?.isNews}
        />
      }

      {hasByline &&
        <Byline left={page.bylineMain} right={page.bylineAside} />
      }
      
      <Stack gap={4} mb={12}>
        {props.pageContext?.hasFeed &&
          <Wrapper>
            <Inner>
              <All {...props} featured pagination itemsToShow={16} category={props.pageContext.category} />
            </Inner>
          </Wrapper>
        }
        {page?.article &&
          <Wrapper>
            <Inner>
              <Article {...page.article} forwardedGridRef={wrapperRef} forwardedMainRef={contentRef} />
            </Inner>
          </Wrapper>
        }
        {page?.gallery &&
          <Box mb={8}>
            <Peek 
              element={`gallery-${page?.slug}`} 
              options={{ 
                perView: 1, 
                gap: 0, 
                rewind: false,
                peek: { 
                  before: peekLeft, 
                  after: peekRight 
                } 
              }}
              key={peekLeft}
              arrows
              count
            >
              {page.gallery.items?.map((x,i) => {
                return (
                  <Box as="figure" sx={carouselSlideSX} key={i}>
                    <img src={x.image?.file?.url} alt="" />
                    <Text as="figcaption" sx={captionSX}>{x.title}</Text>
                  </Box>
                )
              })}
            </Peek>
          </Box>
        }
        {page?.sponsors &&
          <Wrapper sx={{marginTop: 4}}>
            <Inner>
              <SectionHeader title="Our Sponsors" />
              <Grid columns={responsive(1, 4)} py={8}>
                {page.sponsors.map((x,i) => {
                  return <PartnerCard 
                    key={i}
                    title={x.title}
                    content={x.excerpt}
                    image={x.logo?.file?.url}
                  />
                })}
              </Grid>
            </Inner>
          </Wrapper>
        }
        {page?.showLatestNews &&
          <Wrapper sx={{mt: 4}}>
            <Inner>
              <News title={{label: 'Latest FGR News'}} button={{label: 'See more', url: '/news'}} itemsToShow={4} />
            </Inner>
          </Wrapper>
        }
        {page?.showNewsCategory &&
          <Wrapper>
            <Inner>
              <SectionHeader title={`Latest ${page.showNewsCategory.name} News`} button={{label: 'See more', url: `/news/category/${page.showNewsCategory.slug}`}} />
              <All {...props} itemsToShow={4} category={page.showNewsCategory.slug} />
            </Inner>
          </Wrapper>
        }
        {page?.promo &&
          <Wrapper>
            <Inner>
              <Promo data={page?.promo} dark={!props.light} />
            </Inner>
          </Wrapper>
        }
      </Stack> */}

    </Layout>
  )
}

export const pageQuery = graphql`
  query TicketPage {
    contentfulPage(slug: {eq: "ticket-notice"}) {
      title
      strap
      theme
      bylineMain
      bylineAside
      hero {
        ...HeroFragment
      }
      article {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
      gallery {
        ...GalleryFragment
      }
      showLatestNews
      showNewsCategory {
        name
        slug
      }
      sponsors {
        title
        tier
        slug
        excerpt
        logo {
          file {
            url
          }
        }
      }
      promo {
        ...PromoFragment
      }
    }
  }
`

export default Tickets;